<template>
  <div class="service-root-container">
    <div class="page-title">已添加的服务</div>
    <tip-board
      style="margin-top:50px;margin-right: 150px;"
      title="当前没有外部服务"
      sub-title="可能由于"
      :source="icons.servicePlaceholder"
    ></tip-board>
  </div>
</template>

<script>
import * as icon from "../../common/icon";
import TipBoard from "../../components/TipBoard";
export default {
  components: {
    "tip-board": TipBoard,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.service-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  background-color: #f9f9f9;
}
</style>