<template>
  <div class="tip-board-root-container">
    <div class="tip-board-title-container">
      <div style="font-size: 32px; font-weight: bold; color: #000000">
        {{ title }}
      </div>
      <div
        v-if="subTitle"
        style="
          font-size: 12px;
          font-weight: 500;
          color: #7d7d7d;
          margin-top: 20px;
        "
      >
        {{ subTitle }}
      </div>
      <slot name="button"></slot>
    </div>
    <div
      class="tip-board-image"
      :style="{ backgroundImage: 'url(' + source + ')' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: {
      type: String,
      default: "",
    },
    source: String,
  },
};
</script>

<style scoped>
.tip-board-root-container {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 15px;
  height: 200px;
  overflow: hidden;
}
.tip-board-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 70px;
}
.tip-board-image {
  width: 400px;
  height: 100%;
  background-position: center;
  background-size: cover;
}
</style>